import qs from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useRouterQuery() {
  let { search } = useLocation();

  let query = useMemo(() => qs.parse(search.substring(1)), [search]);

  return query;
}


