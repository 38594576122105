import { useState, useCallback } from 'react';
import { registerNewUser } from '@entegral/helpers.axios.myproperty/api/register-new-user';
import { ValidationMessages } from '../constants/validation-messages';
import { empty } from '@entegral/helpers.react/constants/empty';

export function useRequestRegisterCodeForm({
  domainName,
  showSpinner=empty,
  hideSpinner=empty,
  showFailNotification=empty,
  afterRequest=empty,
}) {
  let [ email, setEmail ] = useState('');
  let [ name, setName ] = useState('');

  let requestCode = useCallback(async () => {
    if (!email) {
      showFailNotification({ message: ValidationMessages.noEmail });
      return;
    }

    if (!name) {
      showFailNotification({ message: ValidationMessages.noName });
      return;
    }

    showSpinner();

    try {
      await registerNewUser(domainName, email, name);
      afterRequest(email, name);
    } catch (error) {
      showFailNotification({ message: error.message });
    }

    hideSpinner();
  }, [domainName, email, name, afterRequest, showSpinner, hideSpinner, showFailNotification]);

  return {
    email,
    setEmail,
    name,
    setName,
    requestCode,
  };
}
