import React from 'react';
import { useRouterQuery } from '@entegral/helpers.react/hooks/router-query';
import { useNotifications } from '@entegral/components.notifications/hooks/notifications';
import { useVerifySignInCodeForm } from '@entegral/ui.shared.myproperty.account/hooks/verify-sign-in-code-form';
import { Textbox } from '@entegral/components.textbox';

export function SignInActivate({
  domainName,
  loader,
}) {
  let query = useRouterQuery();
  let { showFailNotification } = useNotifications();

  let {
    code,
    setCode,
    verifyCode,
  } = useVerifySignInCodeForm({
    domainName,
    query,
    showSpinner: loader.startLoading,
    hideSpinner: loader.endLoading,
    showFailNotification,
  });

  return (
    <>
      <div className={`card__row`}>
        <Textbox
          value={code}
          onChange={setCode}
          placeholder={`Activation code`}
          floatingLabel={true}
          extraClassName={`textbox_type_borderless`}
        />
      </div>

      <div className={`card__row`}>
        <div
          onClick={verifyCode}
          className={`button button_size_s button_pull_right`}
        >
          Send code
        </div>
      </div>
    </>
  );
}
