import React from 'react';
import ReactDOM from 'react-dom';

export function Spinner({
  visible,
  baseClassName=`spinner`,
}) {
  if (!visible) return null;

  return ReactDOM.createPortal(
    <div className={baseClassName} />,
    document.body,
  );
}
