import { useState, useCallback, useMemo } from 'react';

const LOADERS = {};

export function useLoader() {
  let alias = useAlias();
  let [ inProgress, setInProgress ] = useState(false);

  let startLoading = useCallback(() => {
    LOADERS[alias]++;
    setInProgress(true);
  }, []);

  let endLoading = useCallback(() => {
    LOADERS[alias]--;

    if (LOADERS[alias] <= 0) {
      LOADERS[alias] = 0;
      setInProgress(false);
    }
  }, []);

  return {
    inProgress,
    startLoading,
    endLoading,
  };
}

function useAlias() {
  return useMemo(() => {
    let alias = Symbol();

    LOADERS[alias] = 0;

    return alias;
  }, []);
}
