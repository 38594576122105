import React, { useCallback } from 'react';
import classnames from 'classnames';

export function Checkbox({
  checked=false,
  label,
  disabled,
  name,

  baseClassName=`checkbox`,
  extraClassName,

  onChange,
}) {
  let onCheckedChange = useCallback((event) => onChange(event.target.checked, name), [onChange, name]);

  let className = classnames(
    baseClassName,
    extraClassName,
    {
      [`${baseClassName}_state_checked`]: checked,
    },
  );

  return (
    <label className={className}>
      <input
        type="checkbox"
        className={`${baseClassName}__input`}
        disabled={disabled}
        checked={checked}

        onChange={onCheckedChange}
      />

      <span className={`${baseClassName}__label`}>{label}</span>
    </label>
  )
}
