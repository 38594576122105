function composeUrlFromRoute(route, params) {
  let url = route;

  for (let key of Object.keys(params || {})) {
    let paramValue = params[key];
    let paramName = new RegExp(`:${key}`, 'g');

    url = url.replace(paramName, paramValue);
  }

  return url;
}

module.exports = composeUrlFromRoute;
