import { storeHookFactory } from '@entegral/helpers.react/hooks/store-hook-factory';

const defaultOptions = { timeout: 8000, timeoutAfterHold: 2000 };

export const useNotificationsStore = storeHookFactory(
  {
    notifications: [],
    seed: Date.now(),
  },
  {
    add: (store, notification) => ({
      notifications: [
        ...store.notifications,
        {
          id: store.seed,
          ...defaultOptions,
          ...notification,
        },
      ],
      seed: store.seed + 1,
    }),

    remove: (store, id) => ({
      ...store,
      notifications: store.notifications.filter(x => x.id !== id),
    }),
  },
);
