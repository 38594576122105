export const ROUTES = {
  signIn: `/sign-in`,
  signInWithEmail: `/sign-in/with-email`,
  signInSent: `/sign-in/sent`,
  signInActivate: `/sign-in/activate`,

  register: `/register`,
  registerSent: `/register/sent`,
  registerHostWithEmail: `/register/with-email`,
  registerActivate: `/register/activate`,
};
