import { useState, useCallback } from 'react';
import { useSession } from '@entegral/helpers.react/hooks/session';
import { signInWithEmail } from '@entegral/helpers.axios.myproperty/api/sign-in-with-email';
import { ValidationMessages } from '../constants/validation-messages';
import { empty } from '@entegral/helpers.react/constants/empty';

export function useRequestSignInCodeForm({
  domainName,
  showSpinner=empty,
  hideSpinner=empty,
  showFailNotification=empty,
  afterRequest=empty,
}) {
  let { preSession } = useSession();
  let [ email, setEmail ] = useState(preSession?.email || '');
  let [ remember, setRemember ] = useState(preSession?.remember || false);

  let requestCode = useCallback(async () => {
    if (!email) {
      showFailNotification({ message: ValidationMessages.noEmail });
      return;
    }

    showSpinner();

    try {
      await signInWithEmail(domainName, email, remember);
      afterRequest(email, remember);
    } catch (error) {
      showFailNotification({ message: error.message });
    }

    hideSpinner();
  }, [domainName, email, remember, showSpinner, hideSpinner, showFailNotification, afterRequest]);

  return {
    email,
    setEmail,
    remember,
    setRemember,
    requestCode,
  };
}
