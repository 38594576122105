import React, { useMemo } from 'react';
import { useRouterQuery } from '@entegral/helpers.react/hooks/router-query';
import routeWithQuery from '@entegral/helpers.formatters/url/compose-from-route-with-query';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

export function SignInSent() {
  let { email } = useRouterQuery();
  let manualEnterUrl = useMemo(() => routeWithQuery(ROUTES.signInActivate, null, { email }), [email]);

  return (
    <>
      <div className={`card__row card__row_space_large`}>
        <div className={`text text_type_line`}>
          You magic link has been emailed to:
        </div>

        <div className={`text text_type_line text_weight_bold`}>
          {email}
        </div>
      </div>

      <div className={`card__row card__row_space_large`}>
        <div className={`text text_type_line`}>
          Click on the link received to access your account.
        </div>

        <div className={`text text_type_line`}>
          Or you can <Link className={`link`} to={manualEnterUrl}>enter code</Link> manually.
        </div>
      </div>

      <div className={`card__row card__row_space_large`}>
        Didn't receive it? Please check your spam folder.
      </div>

      <div className={`card__row card__row_space_large`}>
        <div>
          <Link to={ROUTES.signIn} className={`link`}>
            Back to sign in
          </Link>
        </div>
      </div>
    </>
  );
}
