import {
  useEffect,
  useCallback,
} from 'react';

import {
  getSessionWithExpiration,
  updateSessionFromToken,
  extractPreSession,
  putSession,
} from '../settings/session';

import { storeHookFactory } from './store-hook-factory';

const useSessionStore = storeHookFactory(
  {
    session: null,
    preSession: null,
    ready: false,
  },
);

export function useSession() {
  let [state, , setState] = useSessionStore();
  let setToken = useSetTokenCallback(state, setState);

  useInitSession(state, setState);

  return {
    session: state.session,
    preSession: state.preSession,
    setToken,
    isSessionReady: state.ready,
  };
}

function useInitSession(state, setState) {
  useEffect(() => {
    (async function () {
      if (state.ready) return;

      let session = await getSessionWithExpiration();

      if (!session) {
        setState({ session: null, preSession: null, ready: true });
      } else if (session.expired) {
        setState({ session: null, preSession: extractPreSession(session), ready: true });
      } else {
        setState({ session, preSession: extractPreSession(session), ready: true });
      }
    })();
  }, []);
}

function useSetTokenCallback(state, setState) {
  return useCallback(async token => {
    let session;
    let preSession;

    if (!token) {
      session = null;
      preSession = extractPreSession(state.session);

      await putSession(preSession);
    } else {
      session = await updateSessionFromToken(token);
      preSession = extractPreSession(session);
    }

    setState({ session, preSession, ready: true });
  }, [state]);
}
