import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useNotifications } from '@entegral/components.notifications/hooks/notifications';
import { useRequestRegisterCodeForm } from '@entegral/ui.shared.myproperty.account/hooks/request-register-code-form';
import routeWithQuery from '@entegral/helpers.formatters/url/compose-from-route-with-query';
import { Textbox } from '@entegral/components.textbox';
import { ROUTES } from '../../routes';

export function RegisterWithEmail({
  domainName,
  loader,
}) {
  let history = useHistory();
  let { showFailNotification } = useNotifications();

  let afterRequest = useCallback(
    (email) => history.push(
      routeWithQuery(
        ROUTES.registerSent,
        null,
        { email },
      )
    ),
    [history],
  );

  let {
    email,
    setEmail,
    name,
    setName,
    requestCode,
  } = useRequestRegisterCodeForm({
    domainName,
    showSpinner: loader.startLoading,
    hideSpinner: loader.endLoading,
    showFailNotification,
    afterRequest,
  });

  return (
    <>
      <div className={`card__row`}>
        <Textbox
          value={email}
          onChange={setEmail}
          placeholder={`Email`}
          floatingLabel={true}
          extraClassName={`textbox_type_borderless`}
        />
      </div>

      <div className={`card__row`}>
        <Textbox
          value={name}
          onChange={setName}
          placeholder={`Name`}
          floatingLabel={true}
          extraClassName={`textbox_type_borderless`}
        />
      </div>

      <div className={`card__row`}>
        <div className={`button button_size_s button_pull_right`} onClick={requestCode}>
          Register
        </div>
      </div>

      <div className={`card__row text text_align_right`}>
        <Link className={`link`} to={ROUTES.signIn}>
          Sign in
        </Link>
      </div>
    </>
  );
}
