import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignIn } from './sign-in';
import { Register } from './register';
import { ROUTES } from '../routes';

export function Anonymous({
  domainName,
  loader,
  heading,
  subheading,
  logo,
}) {
  let currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className={`account container container_type_centered`}>
      {heading && (
        <div className={`account__heading`}>
          {heading}
        </div>
      )}

      {subheading && (
        <div className={`account__subheading`}>
          {subheading}
        </div>
      )}

      {logo && (
        <img
          className={`account__logo`}
          src={logo}
        />
      )}

      <div className={`card account__card`}>
        <Switch>
          <Route path={ROUTES.signIn}>
            <SignIn
              domainName={domainName}
              loader={loader}
            />
          </Route>

          <Route path={ROUTES.register}>
            <Register
              domainName={domainName}
              loader={loader}
            />
          </Route>

          <Redirect to={ROUTES.signIn} />
        </Switch>
      </div>

      <div className={`account__links`}>
        <div className={`account__link-slot`}>
          © {currentYear} Entegral, Inc.
        </div>

        <div className={`account__link-slot`}>
          <a
            className={`link`}
            href={`https://www.entegral.net/terms-and-conditions/`}
            target={`__blank`}
            rel={`noreferrer noopener`}
          >
            Terms of Service
          </a>
        </div>

        <div className={`account__link-slot`}>
          <a
            className={`link`}
            href={`https://www.entegral.net/privacy-policy/`}
            target={`__blank`}
            rel={`noreferrer noopener`}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}
