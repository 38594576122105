import React from 'react';
import { useRouterQuery } from '@entegral/helpers.react/hooks/router-query';
import { useNotifications } from '@entegral/components.notifications/hooks/notifications';
import { useVerifyRegisterCodeForm } from '@entegral/ui.shared.myproperty.account/hooks/verify-register-code-form';
import { Textbox } from '@entegral/components.textbox';

export function RegisterActivate({
  domainName,
  loader,
}) {
  let query = useRouterQuery();
  let { showFailNotification } = useNotifications();

  let {
    code,
    setCode,
    verifyCode,
  } = useVerifyRegisterCodeForm({
    domainName,
    query,
    showSpinner: loader.startLoading,
    hideSpinner: loader.endLoading,
    showFailNotification,
  });

  return (
    <>
      <div className={`card__row`}>
        <Textbox
          value={code}
          onChange={setCode}
          placeholder={`Activation code`}
          floatingLabel={true}
          extraClassName={`textbox_type_borderless`}
        />
      </div>

      <div className={`card__row`}>
        <div onClick={verifyCode} className={`button button_size_s button_pull_right`}>
          Send code
        </div>
      </div>
    </>
  );
}
