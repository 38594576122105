import React from 'react';
import { useLoader } from '@entegral/helpers.react/hooks/loader';
import { useAccount } from '@entegral/ui.shared.myproperty.account/hooks/account';
import { useRedirectIfAuthenticated } from '@entegral/ui.shared.myproperty.account/hooks/redirect-if-authenticated';
import { HashRouter as Router } from 'react-router-dom';
import { Anonymous } from './screens/anonymous';
import { Notifications } from '@entegral/components.notifications/ui/notifications';
import { Spinner } from '@entegral/components.spinner';

export function Account({
  domainName,
  heading,
  subheading,
  logo,
  redirectTo,
}) {
  let {
    isAccountInited,
    isAuthenticated,
  } = useAccount({ domainName });

  let globalLoader = useLoader();

  useRedirectIfAuthenticated({ isAuthenticated, redirectTo });

  return (
    <Router>
      {isAccountInited && !isAuthenticated && (
        <>
          <Anonymous
            domainName={domainName}
            loader={globalLoader}
            heading={heading}
            logo={logo}
            subheading={subheading}
          />

          <Spinner visible={globalLoader.inProgress} />

          <Notifications />
        </>
      )}
    </Router>
  );
}
