const qs = require('qs');
const composeUrlFromRoute = require('./compose-from-route');

function composeUrlFromRouteWithQuery(route, routeParams, queryParams) {
  let url = composeUrlFromRoute(route, routeParams);
  let query = qs.stringify(queryParams);

  return `${url}?${query}`;
}

module.exports = composeUrlFromRouteWithQuery;
