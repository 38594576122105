import React from 'react';
import ReactDOM from 'react-dom';
import { Account } from './app';
import { componentData } from '@entegral/helpers.react/settings/component-data';
import { rootNode } from '@entegral/helpers.react/settings/root-node';

ReactDOM.render(
  <Account {...componentData} />,
  rootNode,
);
