import React from 'react';
import classnames from 'classnames';
import { useNotificationLifecycle } from '../hooks/notification-lifecycle';

export function Notification({
  notification,
  baseClassName,
  hide,
}) {
  let { type, title, message } = notification;
  let className = classnames(baseClassName, `${baseClassName}_type_${type}`);

  let { onClick, onMouseEnter, onMouseLeave } = useNotificationLifecycle(notification, hide);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        !!title && (
          <div
            className={`${baseClassName}__title`}
          >
            {title}
          </div>
        )
      }

      <div className={`${baseClassName}__message`}>
        {message}
      </div>
    </div>
  );
}
