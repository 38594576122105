import { useEffect } from 'react';

export function useRedirectIfAuthenticated({
  isAuthenticated,
  redirectTo,
}) {
  useEffect(() => {
    if (!isAuthenticated) return;

    window.location.href = redirectTo;
  }, [isAuthenticated, redirectTo]);
}
