import { useState, useEffect } from 'react';

function setState(store, newState) {
  store.state = newState;
  store.listeners.forEach(listener => listener(store.state));
}

function useStore(store) {
  let [, newListener] = useState();

  useEffect(() => {
    store.listeners.push(newListener);

    return () => {
      store.listeners = store.listeners.filter(listener => listener !== newListener);
    };
  }, [store]);

  return [store.state, store.actions, store.setState];
}

function associateActions(store, actions) {
  const associatedActions = {};

  Object.keys(actions).forEach((key) => {
    if (typeof actions[key] === 'function') {
      associatedActions[key] = (...payload) => {
        let newState = actions[key](store.state, ...payload);
        store.setState(newState);
      };
    }

    if (typeof actions[key] === 'object') {
      associatedActions[key] = associateActions(store, actions[key]);
    }
  });

  return associatedActions;
}

export const storeHookFactory = (initialState, reducers={}) => {
  const store = { state: initialState, listeners: [] };

  store.setState = setState.bind(null, store);
  store.actions = associateActions(store, reducers);

  return useStore.bind(null, store);
};
