import { useState, useCallback } from 'react';
import { useAccountStore } from './account-store';
import { useSession } from '@entegral/helpers.react/hooks/session';
import { useExclusiveAsyncEffect } from '@entegral/helpers.react/hooks/exclusive-async-effect';
import { activateSession } from '@entegral/helpers.axios.myproperty/api/activate-session';
import { ValidationMessages } from '../constants/validation-messages';
import { empty } from '@entegral/helpers.react/constants/empty';

export function useVerifySignInCodeForm({
  domainName,
  query,
  showSpinner=empty,
  hideSpinner=empty,
  showFailNotification=empty,
}) {
  let [ , actions ] = useAccountStore();
  let { setToken } = useSession();
  let [ code, setCode ] = useState(query.code);

  let verifyCode = useCallback(async () => {
    if (!code) {
      showFailNotification({ message: ValidationMessages.noCode });
      return;
    }

    showSpinner();

    try {
      let { token } = await activateSession(domainName, query.email, code.toUpperCase());

      await setToken(token);

      actions.authenticate();
    } catch (error) {
      showFailNotification({ message: error.message });
    }

    hideSpinner();
  }, [domainName, code, query, setToken, showSpinner, hideSpinner, showFailNotification]);

  useExclusiveAsyncEffect(async () => {
    if (!query.code) return;

    await verifyCode();
  }, [query]);

  return {
    code,
    setCode,
    verifyCode,
  };
}
