import localforage from 'localforage';

const SESSION_KEY = `__session`;

export async function putSession(session) {
  return localforage.setItem(SESSION_KEY, session);
}

export async function updateSessionFromToken(token) {
  const tokenPayload = JSON.parse(atob(token.split('.')[1]));

  const session = {
    token,
    ...tokenPayload,
    validBefore: new Date(tokenPayload.validBefore),
  };

  await putSession(session);

  return getSession();
}

export async function getSession() {
  return localforage.getItem(SESSION_KEY);
}

export async function getSessionWithExpiration() {
  const session = await getSession();

  if (!session) {
    return null;
  }

  if (!session.validBefore || session.validBefore < Date.now()) {
    return { ...session, expired: true };
  }

  return session;
}

export function extractPreSession(session) {
  if (!session) return null;

  return {
    email: session.email,
    name: session.name,
    remember: session.remember,
  };
}
