import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RegisterWithEmail } from './with-email';
import { RegisterSent } from './sent';
import { RegisterActivate } from './activate';
import { ROUTES } from '../../routes';

export function Register({
  domainName,
  loader,
}) {
  return (
    <Switch>
      <Route exact path={ROUTES.registerHostWithEmail}>
        <RegisterWithEmail
          domainName={domainName}
          loader={loader}
        />
      </Route>

      <Route exact path={ROUTES.registerSent}>
        <RegisterSent
          domainName={domainName}
        />
      </Route>

      <Route exact path={ROUTES.registerActivate}>
        <RegisterActivate
          domainName={domainName}
          loader={loader}
        />
      </Route>

      <Redirect to={ROUTES.registerHostWithEmail} />
    </Switch>
  );
}
