import axios from 'axios';

import { unpack } from './utils/unpack';
import { patchOptionsWithAuthHeader } from './utils/patch-options-with-auth-header';

export function createApi(baseUrl, options={}) {
  const api = axios.create({ baseURL: baseUrl, ...options });

  async function get(url, token, options={}) {
    patchOptionsWithAuthHeader(options, token);

    return unpack( api.get(url, options) );
  }

  async function post(url, data, token, options={}) {
    patchOptionsWithAuthHeader(options, token);

    return unpack( api.post(url, data, options) );
  }

  async function put(url, data, token, options={}) {
    patchOptionsWithAuthHeader(options, token);

    return unpack( api.put(url, data, options) );
  }

  async function del(url, token, options={}) {
    patchOptionsWithAuthHeader(options, token);

    return unpack( api.delete(url, options) );
  }

  return { api, get, post, put, delete: del };
}
