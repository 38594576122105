import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignInWithEmail } from './with-email';
import { SignInSent } from './sent';
import { SignInActivate } from './activate';
import { ROUTES } from '../../routes';

export function SignIn({
  domainName,
  loader,
}) {
  return (
    <Switch>
      <Route exact path={ROUTES.signInWithEmail}>
        <SignInWithEmail
          domainName={domainName}
          loader={loader}
        />
      </Route>

      <Route exact path={ROUTES.signInSent}>
        <SignInSent
          domainName={domainName}
        />
      </Route>

      <Route exact path={ROUTES.signInActivate}>
        <SignInActivate
          domainName={domainName}
          loader={loader}
        />
      </Route>

      <Redirect to={ROUTES.signInWithEmail} />
    </Switch>
  );
}
