import React from 'react';
import classnames from 'classnames';
import { useNotifications } from '../hooks/notifications';
import { Notification } from './notification';

export function Notifications({
  baseClassName=`notification`,
  groupBaseClassName=`notifications`,
  extraClassName,
}) {
  let {
    notifications,
    hideNotification,
  } = useNotifications();

  return (
    <div className={classnames(groupBaseClassName, extraClassName)}>
      {
        notifications.map(x => (
          <Notification
            key={x.id}
            notification={x}
            baseClassName={baseClassName}
            hide={hideNotification}
          />
        ))
      }
    </div>
  );
}
