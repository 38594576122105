import { useBoolean } from './boolean';
import { useEffect } from 'react';

export function useExclusiveAsyncEffect(fn, deps) {
  let [isRunning, run, stop] = useBoolean(false);

  return useEffect(() => {
    if (isRunning) return;

    (async function () {
      run();

      await fn();

      stop();
    })();
  }, deps);
}
