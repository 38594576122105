import { storeHookFactory } from '@entegral/helpers.react/hooks/store-hook-factory';

export const useAccountStore = storeHookFactory(
  {
    isAccountInited: false,
    isAuthenticated: false,
  },
  {
    init: state => ({ ...state, isAccountInited: true }),
    authenticate: state => ({ ...state, isAuthenticated: true }),
    unauthenticate: state => ({ ...state, isAuthenticated: false }),
  }
);
