import { useCallback } from 'react';
import { useAccountStore } from './account-store';
import { useSession } from '@entegral/helpers.react/hooks/session';
import { useExclusiveAsyncEffect } from '@entegral/helpers.react/hooks/exclusive-async-effect';
import { heartbeat } from '@entegral/helpers.axios.myproperty/api/heartbeat';

export function useAccount({
  domainName,
}) {
  let [ account, actions ] = useAccountStore();
  let { isAccountInited, isAuthenticated } = account;
  let { session, setToken, isSessionReady, preSession } = useSession();

  useExclusiveAsyncEffect(async () => {
    if (!isSessionReady || isAccountInited) return;

    if (session) {
      try {
        await heartbeat(domainName, session);
        actions.authenticate();
      } catch {
        await setToken(null);
      }
    }

    actions.init();
  }, [session, isSessionReady, isAccountInited, domainName, setToken]);

  let unauthenticate = useCallback(async () => {
    actions.unauthenticate();
    await setToken(null);
  }, [setToken]);

  return {
    session,
    preSession,
    isAccountInited,
    isAuthenticated,
    setToken,
    unauthenticate,
  };
}
