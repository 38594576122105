import { useCallback, useEffect, useState } from 'react';

export function useNotificationLifecycle(notification, hideNotification) {
  let { timeout, timeoutAfterHold, id } = notification;
  let [ holded, setHolded ] = useState(false);
  let [ timer, setTimer ] = useState(null);
  let [ waitTime, setWaitTime ] = useState(timeout);

  useEffect(() => {
    if (holded) return;

    let timer = setTimeout(() => hideNotification(id), waitTime);

    setTimer(timer);

    return () => clearTimeout(timer);
  }, [holded]);

  let onClick = useCallback(() => {
    clearTimeout(timer);
    hideNotification(id);
  }, [timer]);

  let onMouseEnter = useCallback(() => {
    setHolded(true);
  }, []);

  let onMouseLeave = useCallback(() => {
    setWaitTime(timeoutAfterHold);
    setHolded(false);
  }, []);

  return {
    onClick,
    onMouseEnter,
    onMouseLeave,
  };
}
