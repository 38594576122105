import { useCallback } from 'react';
import { useNotificationsStore } from './notifications-store';

export function useNotifications() {
  let [store, actions] = useNotificationsStore();

  let { notifications } = store;
  let { add, remove } = actions;

  let showSuccessNotification = useCallback(options => add({ ...options, type: `success` }), []);
  let showFailNotification = useCallback(options => add({ ...options, type: `fail` }), []);

  return {
    notifications,
    showSuccessNotification,
    showFailNotification,
    hideNotification: remove,
  };
}
